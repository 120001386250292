import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

const stripe = loadStripe(
  "pk_test_51Ihr21CHOObJkPNer3zvdEMO4o46q5JjXtilvuTvLWAlLDJ9TBLRt8gzc24QNiSYqOyuZTccN11BOTA50SKBwjmr00IJ7DPcdA"
);

export const CheckoutPage = () => {
  let params = queryString.parse(window.location.search);
  let data = JSON.parse(params.data);

  const getSessionId = async () => {
    let response = await axios.post("https://pay.staging.pikado.io/pay/", {
      userPackages: data.userPackages,
      studentCountry: data.studentCountry,
    });
    return response.data.sessionId;
  };

  const redirectToCheckout = async (sessionId) => {
    const stripeInit = await stripe;
    return await stripeInit.redirectToCheckout({
      sessionId: sessionId,
    });
  };
  useEffect(() => {
    (async function () {
      try {
        let sessionId = await getSessionId();
        console.log("sessionId->", sessionId);
        let result = await redirectToCheckout(sessionId);
      } catch (e) {
        console.log("hiNO->", e);
      }
    })();
  }, []);

  //window.ReactNativeWebView?.postMessage("hi");
  return (
    <div
      style={{
        alignItems: "center",
        margin: "200px",
      }}
    >
      {/*<button
        onClick={() => {
          window.ReactNativeWebView?.postMessage("hi");
          console.log("presssed");
        }}
      >
        hos21
      </button>*/}
      {/*<p>Loading ...</p>*/}
    </div>
  );
};
