import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { axios } from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { CheckoutPage } from "./pages/Checkout";
import { Payment_cancel } from "./pages/Payment_cancel";
import { Payment_success } from "./pages/Payment_success";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/">
          <CheckoutPage />
        </Route>
        <Route path="/Payment_success">
          <Payment_success />
        </Route>
        <Route path="/Payment_cancel">
          <Payment_cancel />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
